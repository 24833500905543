<template>
	<ButtonGeneric
		:id="id"
		:class="btnClass"
		:disabled="disabled"
		@clicked="clicked">
		<slot />
	</ButtonGeneric>
</template>

<script setup>
	const { id } = defineProps({
		id: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	})

	const runtimeConfig = useRuntimeConfig()
	const invertColors = runtimeConfig.public.COLORS && runtimeConfig.public.COLORS.INVERT_PRIMARY_BTNS
	const btnClass = invertColors ? "button-primary-inverted" : "button-primary"

	const emits = defineEmits(["clicked"])
	const clicked = () => {
		emits("clicked", { id: id })
	}
</script>

<style lang="scss" scoped>
	.button-primary {
		@apply bg-primary text-secondary hover:text-primary hover:bg-secondary;
	}

	.button-primary-inverted {
		@apply bg-secondary text-primary hover:text-secondary hover:bg-primary;
	}
</style>
