//Cart Related Events
export const CART_CLEARED = "cart:cart_cleared"

export const SHOW_SEAT_SELECTOR_POPUP = "seats_io:show_seat_selector_popup"
export const HIDE_SEAT_SELECTOR_POPUP = "seats_io:hide_seat_selector_popup"

//This event occurs when somebody tries to checkout with a seat that is already booked
export const USER_MUST_EDIT_SEAT_SELECTION = "seats_io:user_must_edit_selection"

export const SHOW_SEAT_PREVIEW_POPUP = "seats_io:show_seat_preview_popup"
export const HIDE_SEAT_PREVIEW_POPUP = "seats_io:hide_seat_preview_popup"

export const REFRESH_CART = "cart:rewrite_cart"

export const MODIFIED_RESERVED_ITEM = "seats_io:modified_item"

export const UPDATE_BILLING_COUNTRY = "cart:changed_currency_via_country"
