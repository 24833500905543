//This Store is intended to act as a global cache to share data across all pages.
//Use is to be restricted to data that comes from the backend or global settings/flags.
export const useSettingsStore = defineStore("settingsStore", () => {
	const maxInstallmentCount = ref(1)
	const showRestrictedCheckoutPage = ref(false)

	//This flag will help dictate if any onBeforeRouteLeave guards should be triggered
	const userIsBeingRedirected = ref(false)

	//Tracks if the SeatSelector Popup should be open (used in case of a page refresh)
	const isSelectingSeat = ref(false)

	const isSlowNetwork = ref(false)

	const setMaxInstallmentCount = (value = 1) => (maxInstallmentCount.value = value)
	const setShowRestrictedCheckoutPage = (value: boolean) => (showRestrictedCheckoutPage.value = value)

	const setUserIsBeingRedirected = (value: boolean) => (userIsBeingRedirected.value = value)

	const setIsSelectingSeat = (value: boolean) => (isSelectingSeat.value = value)

	const setIsSlowNetwork = (value: boolean) => (isSlowNetwork.value = value)

	const setSettingsStore = (settingsStore: any) => {
		//Do not persist: maxInstallmentCount
		showRestrictedCheckoutPage.value = settingsStore.showRestrictedCheckoutPage
		isSelectingSeat.value = settingsStore.isSelectingSeat
	}

	return {
		maxInstallmentCount,
		setMaxInstallmentCount,
		showRestrictedCheckoutPage,
		setShowRestrictedCheckoutPage,
		userIsBeingRedirected,
		setUserIsBeingRedirected,
		isSelectingSeat,
		setIsSelectingSeat,
		isSlowNetwork,
		setIsSlowNetwork,
		setSettingsStore,
	}
})
