import { useSettingsStore } from "~/stores/SettingsStore"

export default defineNuxtPlugin((nuxtApp) => {
	const settingsStore = useSettingsStore()

	const SLOW_NETWORK_RESPONSE_TIME_THRESHOLD = 200 //Network responses longer than 200ms will be considered slow
	let SLOW_NETWORK_TIMEOUT = null

	const startDetectingSlowConnection = () => {
		SLOW_NETWORK_TIMEOUT = setTimeout(() => {
			settingsStore.setIsSlowNetwork(true)
		}, SLOW_NETWORK_RESPONSE_TIME_THRESHOLD)
	}

	const finishDetectingSlowConnection = () => {
		if (SLOW_NETWORK_TIMEOUT) {
			settingsStore.setIsSlowNetwork(false)
		}
		clearTimeout(SLOW_NETWORK_TIMEOUT)
	}

	nuxtApp.provide("utilities", {
		$handleImportModuleError: (errorMsg) => {
			const importModuleErrors = ["Failed to fetch dynamically imported module", "Failed to load module script"]

			const containsError = importModuleErrors.some((msg) => errorMsg.includes(msg))

			if (containsError) {
				const cookie = useCookie("lastRefreshTime", { maxAge: 3600 })
				const lastRefreshTime = cookie.value
				const expirationTime = 5 * 60 * 1000 // 5 minutes
				const now = new Date().getTime()
				const isTimePassed = lastRefreshTime && now - parseInt(lastRefreshTime) > expirationTime

				if (!lastRefreshTime || isTimePassed) {
					cookie.value = now
					window.location.reload()
					return false
				}
			}

			return true
		},

		$isMobileDevice() {
			return window.matchMedia("only screen and (max-width: 760px)").matches
		},

		$startDetectingSlowConnection: startDetectingSlowConnection,

		$finishDetectingSlowConnection: finishDetectingSlowConnection,
	})
})
