import { useNuxtApp } from "#app"
import { useProductStore } from "~/stores/ProductStore"
import { useCartStore } from "~/stores/CartStore"
import { useSettingsStore } from "~/stores/SettingsStore"
import Product from "~/models/Product"
import { storeToRefs } from "pinia"

export default defineNuxtPlugin((nuxtApp) => {
	const { $productsApiService, $apiHelper, $utilities } = useNuxtApp()
	const productStore = useProductStore()
	const cartStore = useCartStore()
	const settingsStore = useSettingsStore()
	const { cartId, exchangeRate } = storeToRefs(cartStore)

	nuxtApp.provide("productsService", {
		$getProductById: async (productId) => {
			const productsExchangeRate = cartId.value ? exchangeRate.value : null
			const { data, pending, status } = await $productsApiService.$getProductById(productId, productsExchangeRate)

			$apiHelper.$handleResponse(pending, status, data, (productData) => {
				const product = Product.createFromPartial(productData.product)
				productStore.addProduct(product)
			})
		},

		$getProductsByType: async (productTypeId) => {
			const productsExchangeRate = cartId.value ? exchangeRate.value : null
			$utilities.$startDetectingSlowConnection()
			const { data, pending, status } = await $productsApiService.$getProductsByType(
				productTypeId,
				productsExchangeRate
			)

			$apiHelper.$handleResponse(pending, status, data, (productData) => {
				$utilities.$finishDetectingSlowConnection()
				productStore.removeProductsByTypeId(productTypeId)
				productData.products.forEach((item) => {
					const product = Product.createFromPartial(item)
					productStore.addProduct(product)
				})
			})
		},

		$getAppSettings: async () => {
			$utilities.$startDetectingSlowConnection()
			const { data, pending, status } = await $productsApiService.$getAppSettings()

			$apiHelper.$handleResponse(pending, status, data, (data) => {
				$utilities.$finishDetectingSlowConnection()
				settingsStore.setMaxInstallmentCount(data.maxInstallmentCount)
			})
		},
	})
})
